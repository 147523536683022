<template>
    <div class="question-list base-bg">
        <div class="question-type">
            <ul>
                <li @click="seeHot(item)" v-for="item in second.list" :key="item.name">
                    <a :class="{active:type.active===item.name}" href="javascript:void(0);">{{ item.title }}</a>
                </li>
            </ul>
        </div>
        <div>
            <question v-for="q in answer" :key="q.answer_id" :content="q"></question>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                @size-change="sizeChange"
                @current-change="pageChange"
                :current-page="page.pageNumber"
                :page-sizes="[10, 15, 30, 50]"
                :page-size="page.pageSize"
                :background="true"
                layout="sizes,prev, pager,next,total"
                :total="page.total">
            </el-pagination>
        </div>
<!--        <div v-if="mode!=='index'" class="q-footer-more">-->
<!--            <div class="click-more">-->
<!--                <span v-if="second.has_more" @click="getMore">-->
<!--                    单击加载更多 <i class="el-icon-d-arrow-right"></i>-->
<!--                </span>-->
<!--                <span v-else>没有更多数据了</span>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
import Question from "@/components/wenda/left/question";
import F from "@/fields/fields";

export default {
    name: "question-list",
    props: {
        mode: {
            default: '',
        },

    },
    components: {
        Question
    },
    mounted() {
        let active = this.$route.params && this.$route.params.type || 'all';
        let all = this.second.list.map(v => v.name);
        this.type.active = all.indexOf(active) > -1 ? active : 'all';
    },
    watch: {
        'type.active': function () {
            // this.answer.splice(0, 100000);
            this.search.answer_id = [];
            this.page.pageNumber = 1;
            this.search.pageNumber = 1;
            this.search.type_id = this.type.active;
            this.getAnswer();
        }
    },
    data() {
        return {
            baseUrl: '/bbs/answer/index',
            answer: [],
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            },
            search: {
                pageNumber: 1,
                answer_id: [],
            },
            page: {
                search: {},
                sort: {},
                total: 1,
                pageSize: 10,
                pageNumber: 1,
            },
            second: {
                active: '',
                has_more: 0,//这个是用来计算本次获取了多少条数据，方便展示更多
                list: [
                    {title: '全部', name: 'all'},
                    // {title: '资讯', name: 'news'},
                    // {title: '热榜', name: 'hot'},
                    // {title: '开源引力', name: 'open'},
                    {title: 'Web前端', name: 'web'},
                    {title: 'JavaScript', name: 'js'},
                    {title: 'PHP', name: 'php'},
                    {title: 'Linux', name: 'linux'},
                    {title: '后端', name: 'back'},
                    {title: '工作', name: 'work'},
                    {title: '杂项', name: 'else'},
                ]
            },
            type: {
                active: 'index',
                list: [
                    {
                        name: 'index',
                        title: '全部文章'
                    },
                    {
                        name: 'unanswered',
                        title: '等待回答'
                    },
                    {
                        name: 'done',
                        title: '已完成'
                    },
                ],
            }
        }
    },

    methods: {
        seeHot(item) {
            this.type.active = item.name;
            this.$router.push({
                path: '/wenda/' + item.name,
            });
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.getAnswer();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.getAnswer();
        },
        getAnswer() {
            //如果是全部那么不要type_id 查看全部的数据
            if (this.search.type_id==='all') this.search.type_id='';
            this.$bbs.get(this.baseUrl, {
                params: {
                    search: this.search,
                    pageSize: this.page.pageSize,
                    pageNumber: this.page.pageNumber,
                }
            }).then(data => {
                let need = data.data.data;
                // console.log(data.data);
                this.page.total = data.data.total;
                this.page.pageNumber = data.data.pageNumber;
                this.answer=need;
                // this.answer.push(...need);
                this.second.has_more = need.length === 10;
            });
        },
        getMore() {
            this.search.pageNumber++;
            this.getAnswer();
        }
    }
}
</script>

<style scoped lang="less">
.question-list {
    padding: 20px;
    border-radius: 4px;
    //background-color: #fff;

    .question-type {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        border-bottom: 1px solid #ccc;

        .my-select {
            border-radius: 50px;
            width: 112px;

            ::v-deep {
                .el-input__inner {
                    border-color: #007EE2;
                    border-radius: 20px;
                }
            }
        }

        ul {
            //flex: 1;
            //padding-left: 100px;
            display: flex;
            margin-bottom: 10px;

            li {
                border-radius: 50px;
                margin-right: 5px;
                &:hover {
                    background-color: rgba(238, 243, 250, 0.71);
                }

                &.active {
                    a {
                        color: #0082fc;
                        border-bottom: 2px solid #0082fc;
                    }
                }

                a {
                    text-decoration: none;
                    display: inline-block;
                    border: 1px solid transparent;
                    padding: 6px 20px;
                    font-size: 14px;
                    color: #31353b;
                    letter-spacing: 1px;

                    &.active {
                        color: #007EE2;
                        border-radius: 50px;
                        border: 1px solid #007EE2;
                    }
                }
            }
        }

        .add-question {
            width: 90px;
        }
    }
}

.click-more {
    text-align: center;
    font-size: 14px;
    background: #e4ecfd;
    border-radius: 2px;
    padding: 15px 0;
    color: #8098B5;
    position: relative;
    cursor: pointer;
}
</style>
