<template>
    <div class="today-hot">
        <div class="title">今日热榜</div>
        <div class="body">
            <div v-for="(item,index) in list" :key="item.answer_id" class="item">
                <div class="index bbs-third-color" :class="['index'+(Math.floor(index/3))]">{{ index + 1 }}</div>
                <a target="_blank" :href="'/wenda/detail/'+item.answer_id+'.html'"
                   class="answer-title bbs-primary-color">
                    {{ item.title }}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            baseUrl: '/bbs/hot/index',
            list: [],
        }
    },
    mounted() {
        this.getHot();
    },
    methods: {
        getHot() {
            this.$bbs.get(this.baseUrl, {
                params: {
                    type: 'top',
                }
            }).then(data => {
                this.list = data.data.top;
            });
        }
    }
}
</script>
<style scoped lang="less">
.today-hot {
    //margin-top: 15px;
    padding: 15px;
    //background-color: #fff;

    .body {
        .item {
            display: flex;
            padding: 6px 0;
            line-height: 20px;

            .index {
                width: 30px;
                text-align: center;
                flex-shrink: 0;

                &.index0 {
                    color: #fc5d5d;
                }
            }

            .answer-title {

                display: block;
                flex: 1;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis; //省略号
                white-space: nowrap; //强制一行显示
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
</style>
