<template>
    <div class="search-area">
        <div class="input-item">
            <div>
                <input v-model="value" class="search-input" type="text" placeholder="搜索你想知道的">
                <span class="search-icon">
                    <i class="el-icon-search"></i>
                    <span class="img">
                        <img src="/static/bbs/images/index/logo_text.png" alt="">
                    </span>
                </span>

                <button class="search-btn" @click="searchBtnClick">搜索答案</button>
            </div>
            <search-result-container :value="searchValue" ref="result" @change="changeData"></search-result-container>
        </div>
        <div class="question-item">
            <button @click="addQuestion" class="add-question">我要提问</button>
        </div>
    </div>
</template>
<script>
import SearchResultContainer from "@/components/common/SearchResultContainer.vue";
export default {
    components: {
        SearchResultContainer,
    },
    props: {
        readOnlyValue: {}
    },
    data() {
        return {
            value: '',
            searchValue: '',
            readOnlyTime: 0,
        }
    },
    watch: {
        value: function () {
            if (this.readOnlyTime && new Date().getTime() - this.readOnlyTime < 200) {
                return;
            }
            this.searchValue = this.value;
        },
        readOnlyValue: function () {
            this.readOnlyTime = new Date().getTime();
            this.value = this.readOnlyValue;
        }
    },
    methods: {
        addQuestion() {
            if (!this.$tools.checkLogin())return;
            this.$router.push({
                path:'/add',
            });
            // this.$store.commit('updateCurrentQuestion',{show:Math.random(),title:'新增问答',data:{}})
        },
        changeData(title) {
            this.value = title.value;
            if (title.search) {
                this.$router.push({
                    path: '/index/search',
                    query: {
                        title: title.value,
                    }
                });
            }
        },
        searchBtnClick() {
            if (!this.value) return;
            this.changeData({search: true, value: this.value});
        }
    }
}
</script>
<style scoped lang="less">
.search-area {
    display: flex;

    .input-item {
        flex: 1;
        position: relative;

        &::after {
            position: absolute;
            top: 54px;
            left: 50%;
            //margin-left: ;
            //color: #F56C6C;
            width: 340px;
            margin-left: -170px;
            color: #ccc;
            content: '只有坚持别人无法坚持的，才能拥有别人无法拥有的';
        }

        .search-input {
            outline-style: none;
            border: none;
            //border: 1px solid #ccc;
            //outline: #6f7a86 solid 1px;
            display: inline-block;
            width: 500px;
            //height: 75px;
            padding: 12px 5px 12px 76px;
            //border-radius: 20px;
            border-bottom-left-radius: 8px;
            border-top-left-radius: 8px;

            &:focus {
                border: none;
                outline: none;
                //outline: #81acdc solid 1px;
                //border: 1px solid #ccc;
            }
        }

        .search-icon {
            top: 14px;
            color: #666;
            left: 10px;
            position: absolute;
            .img{
                display: inline-block;
                padding-left: 6px;
                img{
                    display: inline-block;
                    width: 40px;
                }
            }
        }

        .search-btn {
            outline: none;
            background-color: #307DDB;
            color: #fff;
            border: none;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            padding: 14px 25px;
        }
    }

    .question-item {
        width: 150px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .add-question {
            border-radius: 8px;
            padding: 14px 30px;
            background-color: #fff;
            outline: none;
            border: 1px solid #1C8CE5;
        }

    }
}

</style>
